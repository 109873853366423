<template>
  <div>
    <v-card>
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">SEARCH INVENTORY</h4></v-toolbar-title>
      </v-toolbar>
      <v-layout row wrap class="align-center mx-2 mt-5">
        <v-layout col>
          <v-flex xs12 md2>
            <v-form ref="form2" class="multi-col-validation">
              <div v-if="!saving">
                <v-select
                  outlined
                  class="mx-2"
                  v-model="particulars_id"
                  dense
                  label="Particulars"
                  :items="particulars_items"
                  item-value="id"
                  item-text="particulars"
                  :rules="rules.combobox_rule"
                  @change="selected_particulars"
                ></v-select>
                <v-select
                  outlined
                  class="mx-2"
                  v-model="category_id"
                  dense
                  label="Category"
                  :items="category_items"
                  item-value="id"
                  item-text="category"
                  :rules="rules.combobox_rule"
                  @change="selected_category"
                ></v-select>
                <v-select
                  outlined
                  v-if="!is_forbidden"
                  class="mx-2"
                  v-model="selection"
                  dense
                  label="Selection"
                  :items="['All','Branch', 'Regional', 'Territory']"
                  :rules="rules.combobox_rule"
                  @change="selected_category"
                ></v-select>
                <v-select
                  outlined
                  class="mx-2"
                  v-model="branch_id"
                  dense
                  label="Branch"
                  :items="branch_items"
                  item-value="id"
                  item-text="branch_code"
                  :rules="rules.combobox_rule"
                ></v-select>
                <v-select
                  v-if="particulars_id===2"
                  v-model="car_brand"
                  class="mx-2"
                  dense
                  outlined
                  label="Auto Dealer"
                  :items="car_brand_items"
                  item-value="car_brand"
                  item-text="car_brand"
                  :rules="rules.combobox_rule"
                ></v-select>
                <v-select
                  v-if="particulars_id===1"
                  outlined
                  class="mx-2"
                  v-model="equipment"
                  dense
                  label="Data"
                  :items="equipment_items"
                  item-value="equipment_details"
                  item-text="equipment_details"
                  :rules="rules.combobox_rule"
                  @change="selected_equipment"
                ></v-select>
                <v-select
                  v-if="equipment_code_items.length>0 && particulars_id===1"
                  v-model="equipment_code"
                  class="mx-2"
                  dense
                  outlined
                  label="Code"
                  :items="equipment_code_items"
                  item-value="equipment_code"
                  item-text="equipment_code"
                  @change="selected_equipment_code"
                  :rules="rules.combobox_rule"
                ></v-select>
                <v-select
                  v-if="equipment_components_items.length>0 && particulars_id===1"
                  v-model="equipment_components"
                  class="mx-2"
                  dense
                  outlined
                  label="Specify"
                  :items="equipment_components_items"
                  item-value="equipment_components"
                  item-text="equipment_components"
                  :rules="rules.combobox_rule"
                ></v-select>
                <v-select
                  v-if="particulars_id===1"
                  outlined
                  class="mx-2"
                  v-model="type_of_inventory"
                  dense
                  label="Identify"
                  :items="['NO','YES']"
                  :rules="rules.combobox_rule"
                ></v-select>
              </div>
              <!-- alert -->
              <v-col cols="12" v-show="alert">
                <v-alert
                  color="warning"
                  text
                  class="mb-0"

                >
                  <div class="d-flex align-start">
                    <v-icon color="warning">
                      {{ icons.mdiAlertOutline }}
                    </v-icon>

                    <div class="ms-3">
                      <p class="text-base font-weight-medium mb-1">
                        {{alert_message}}
                      </p>
                    </div>
                  </div>
                </v-alert>
              </v-col>

              <v-col cols="12">
                <v-btn
                  color="primary"
                  class="me-3 mt-4 w-full"
                  @click="search_inventory_data"
                  v-if="!saving"
                >
                  Search
                </v-btn>
                <v-progress-circular
                  :size=50
                  :width="5"
                  color="primary"
                  indeterminate
                  v-else
                ></v-progress-circular>
              </v-col>
            </v-form>
          </v-flex>
          <v-flex xs12 md10>
            <Equipment :key="this.key" v-if="particulars_id===1||particulars_id===''"
                       :data_items="data_items" :category_items="category_items"
                       :branch_items="branch_items" :category_id="category_id"
                       :branch_id="branch_id"
                       :equipment="equipment" :equipment_code="equipment_code"
                       :equipment_components="equipment_components"
                       v-on:response="search_inventory_data"
            ></Equipment>
            <Car :key="this.key" v-if="particulars_id===2"
                 :data_items="data_items" :category_items="category_items"
                 :branch_items="branch_items" :category_id="category_id" :branch_id="branch_id"
                 :equipment="equipment" :equipment_code="equipment_code"
                 :equipment_components="equipment_components"
            ></Car>
            <LandAndBuilding :key="this.key" v-if="particulars_id===3||particulars_id===4"
                             :data_items="data_items" :category_items="category_items"
                             :branch_items="branch_items" :category_id="category_id"
                             :branch_id="branch_id"
                             :equipment="equipment" :equipment_code="equipment_code"
                             :equipment_components="equipment_components"
            ></LandAndBuilding>
          </v-flex>
        </v-layout>
      </v-layout>
    </v-card>
    <snack-bar-dialog :snackbar_flag="this.snackbar" :color="this.snackbar_color"
                      :snackbar_text="this.snackbar_text"/>
  </div>
</template>

<script>
  import {mdiCheck, mdiClose, mdiSwapHorizontal} from '@mdi/js'
  import moment from 'moment'
  import {mapActions, mapGetters} from 'vuex'
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'
  import Equipment from '@/views/file_interface/search/components_inventory/Equipment'
  import Car from '@/views/file_interface/search/components_inventory/Car'
  import LandAndBuilding from '@/views/file_interface/search/components_inventory/LandAndBuilding'

  const initialState = () => {
    return {
      saving: false,
      alert: false,
      alert_message: '',

      is_forbidden: false,
      type_of_inventory: '',
      specify_inventory: '',
      equipment: '',
      particulars_id: '',
      branch_id: 0,
      selection: '',
      category_id: 0,
      specify_items: [],
      type_items: [],
      equipment_items: [],
      particulars_items: [],
      branch_items: [],
      category_items: [],
      car_brand: '',
      car_brand_items: [],

      equipment_code: '',
      equipment_code_items: [],
      equipment_components_items: [],
      equipment_components: '',

      data_items: [],
      key: 0,
    }
  }
  export default {
    components: {
      snackBarDialog,
      Equipment,
      Car,
      LandAndBuilding,
    },
    setup() {
      return {
        icons: {
          mdiCheck,
          mdiClose,
          mdiSwapHorizontal,
        },
      }
    },
    data() {
      return initialState()
    },
    mounted() {
      this.initialize_data()
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo']),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('branch_information', ['branch_data']),
      ...mapActions('inventory', ['initialize_request_inventory', 'initialize_load_stock', 'initialize_load_stock_selected_equipment', 'initialize_load_stock_selected_equipment_code', 'search_inventory']),
      initialize_data() {
        this.initialize_request_inventory()
          .then(response => {
            this.category_items = response.data[0].category
            this.particulars_items = response.data[0].type_of_request
            this.particulars_id = 1;
            this.selected_particulars()
          })
          .catch(error => {
            console.log(error)
          })
      },
      date_format(value) {
        return moment(value)
      },
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      async selected_category() {
        this.is_forbidden = true
        if (this.category_id === 4 || this.category_id === 3) {
          this.is_forbidden = false
        } else {
          this.selection = ''
        }
        await this.branch_data({
          category_id: this.category_id,
          is_region: this.selection === 'Regional' ? 1 : 0,
          is_territory: this.selection === 'Territory' ? 1 : 0,
        })
          .then(response => {
            this.branch_items = response.data[0].active
            this.branch_items.splice(0, 0, {
              id: -1,
              branch_code: 'All',
            })
          })
          .catch(error => {
            console.log(error)
          })
      },
      selected_particulars() {
        this.key++
        this.initialize_load_stock({
          type: this.particulars_items[this.particulars_items.map(function (x) {
            return x.id;
          }).indexOf(this.particulars_id)].particulars,
        })
          .then(response => {
            this.equipment_items = response.data[0].details
            this.car_brand_items = response.data[0].details
          })
          .catch(error => {
            console.log(error)
          })
      },
      selected_equipment(value) {
        this.equipment_code = ''
        this.equipment_components = ''
        this.initialize_load_stock_selected_equipment({
          equipment_details: value,
        })
          .then(response => {
            this.equipment_code_items = response.data
          })
          .catch(error => {
            console.log(error)
          })
      },
      selected_equipment_code(value) {
        this.equipment_components = ''
        this.initialize_load_stock_selected_equipment_code({
          equipment_code: value,
        })
          .then(response => {
            this.equipment_components_items = response.data
            if (this.equipment_components_items.length > 0) {
              this.equipment_components_items.splice(0, 0, {
                id: -1,
                equipment_components: 'All',
              })
            }
          })
          .catch(error => {
            console.log(error)
          })
      },
      search_inventory_data() {
        this.key++
        this.saving = true
        this.alert = false
        if (this.$refs.form2.validate()) {
          this.search_inventory({
            particulars_id: this.particulars_id,
            category_id: this.category_id,
            branch_id: this.branch_id,
            equipment: this.equipment,
            equipment_code: this.equipment_code,
            equipment_components: this.equipment_components,
            auto_dealer: this.car_brand,
            type_of_inventory: this.type_of_inventory,
          })
            .then(response => {
              this.data_items = []
              var dd = []
              response.data[0].inventory.forEach(function (item) {
                dd.push(item)
              });
              var iddd = 0;
              response.data[0].branches_no_data.forEach(function (item) {

                dd.push({
                  id: iddd,
                  transmital_no: item.branch_code,
                  equipment_unit_price: 0,
                  equipment_unit_quantity: 0,
                  specify_remarks: '',
                  auto_unit_value: 0,
                  existing_value: 0,
                  land_value: 0
                })
                iddd--;
              });
              this.data_items = dd
              this.saving = false
            })
            .catch(error => {
              console.log(error)
            })
        } else {
          this.alert = true
          this.alert_message = 'Please Fill up the field/s'
          this.saving = false
        }
      },

    },
  }
</script>
