<template>
  <div>
    <v-card>
      <v-form ref="form" class="multi-col-validation">
        <v-toolbar dense dark color="primary">
          <v-toolbar-title><h4 class="font-weight-light">EDIT DATA</h4></v-toolbar-title>
        </v-toolbar>

        <v-layout row wrap class="align-center mx-2 mt-5">
          <v-flex xs12 md12 v-if="type==='Car'">
            <v-text-field
              class="mx-2"
              v-model="auto_mv_file_no"
              label="MV File #"
              dense
              outlined
              :rules="rules.default_max_45_character_and_no_empty_rule"
            ></v-text-field>
            <v-text-field
              class="mx-2"
              v-model="auto_plate_no"
              label="Plate #"
              dense
              outlined
              :rules="rules.default_max_45_character_and_no_empty_rule"
            ></v-text-field>
            <v-text-field
              class="mx-2"
              v-model="auto_unit_color"
              label="Unit Color"
              dense
              outlined
              :rules="rules.default_max_45_character_and_no_empty_rule"
            ></v-text-field>
            <v-text-field
              class="mx-2"
              v-model="auto_chasis_no"
              label="Chasis #"
              dense
              outlined
              :rules="rules.default_max_45_character_and_no_empty_rule"
            ></v-text-field>
            <v-text-field
              class="mx-2"
              v-model="auto_engine_no"
              label="Engine #"
              dense
              outlined
              :rules="rules.default_max_45_character_and_no_empty_rule"
            ></v-text-field>
          </v-flex>
        </v-layout>

        <v-row>
          <!-- alert -->
          <v-col cols="12" v-show="alert">
            <v-alert color="warning" text class="mb-0">
              <div class="d-flex align-start">
                <v-icon color="warning">
                  {{ icons.mdiAlertOutline }}
                </v-icon>

                <div class="ms-3">
                  <p class="text-base font-weight-medium mb-1">
                    {{ alert_message }}
                  </p>
                </div>
              </div>
            </v-alert>
          </v-col>
          <v-col cols="12" md="12">
            <v-btn class="w-full" color="primary" @click="done_request" v-if="!saving_request">
              Done
            </v-btn>
            <v-progress-circular :size="50" :width="5" color="primary" indeterminate
                                 v-else></v-progress-circular>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
  </div>
</template>
<script>
  import {mdiAlertOutline} from '@mdi/js'
  import {mapActions, mapGetters} from 'vuex'

  const initialState = () => {
    return {
      saving_request: false,
      alert: false,
      alert_message: '',

      auto_mv_file_no: '',
      auto_plate_no: '',
      auto_unit_color: '',
      auto_chasis_no: '',
      auto_engine_no: '',
    }
  }
  export default {
    props: {
      details: Object,
      type: String,
    },
    setup() {
      return {
        icons: {
          mdiAlertOutline,
        },
      }
    },
    data() {
      return initialState()
    },
    mounted() {
      this.initialize_data()
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('authentication', ['employee_id']),
    },
    methods: {
      ...mapActions('inventory_history', ['register_inventory_history']),
      ...mapActions('branch_information', ['branch_data']),
      ...mapActions('category', ['list_of_all_categories']),
      ...mapActions('inventory', ['update_inventory_basic_info']),
      initialize_data() {
        this.auto_mv_file_no = this.details.auto_mv_file_no
        this.auto_plate_no = this.details.auto_plate_no
        this.auto_unit_color = this.details.auto_unit_color
        this.auto_chasis_no = this.details.auto_chasis_no
        this.auto_engine_no = this.details.auto_engine_no
      },
      async selected_category() {
        this.is_forbidden = true
        if (this.category_id === 4 || this.category_id === 3) {
          this.is_forbidden = false
        } else {
          this.selection = ''
        }
        this.branch_id = ''
        await this.branch_data({
          category_id: this.category_id,
          is_region: this.selection === 'Regional' ? 1 : 0,
          is_territory: this.selection === 'Territory' ? 1 : 0,
        })
          .then(response => {
            this.branch_items = response.data[0].active
          })
          .catch(error => {
            console.log(error)
          })
      },
      done_request() {
        this.saving_request = true
        this.alert = false
        if (this.$refs.form.validate()) {
          const data = new FormData()
          data.append('id', this.details.id)
          data.append('type', this.type)
          data.append('auto_mv_file_no', this.auto_mv_file_no.toUpperCase())
          data.append('auto_plate_no', this.auto_plate_no.toUpperCase())
          data.append('auto_unit_color', this.auto_unit_color.toUpperCase())
          data.append('auto_chasis_no', this.auto_chasis_no.toUpperCase())
          data.append('auto_engine_no', this.auto_engine_no.toUpperCase())
          this.update_inventory_basic_info(data)
            .then(response => {
              this.$emit('response', response)
              this.saving_data = false
            })
            .catch(error => {
              console.log(error)
            })
        } else {
          this.alert = true
          this.alert_message = 'Please Fill up the field/s'
          this.saving_request = false
        }
      },
    },
  }
</script>
